<template>
    <div class="footer">
        <el-tabs v-model="activeName" class="friendly-link" v-if="linkList.length > 0">
            <el-tab-pane label="友情链接" name="first">
                <div>
                    <div class="link-item" v-for="(link_item, link_index) in linkList" :key="link_index" :title="link_item.link_title">
                        <span @click="linkUrl(link_item.link_url, link_item.is_blank)"><img :src="$img(link_item.link_pic)"/></span>
                    </div>

                    <div class="clear"></div>
                </div>
            </el-tab-pane>
        </el-tabs>
        <div class="footer-top">
            <div class="service">
                <div class="flex">
                    <img src="@/assets/images/footer_01.png" alt="">
                    <div class="text">品质保障</div>
                </div>
				<div class="flex">
				    <img src="@/assets/images/footer_02.png" alt="">
				    <div class="text">全国包邮</div>
				</div>
				<div class="flex">
				    <img src="@/assets/images/footer_03.png" alt="">
					<div class="text">售后无忧</div>
				</div>
				<div class="flex">
				    <img src="@/assets/images/footer_04.png" alt="">
				    <div class="text">放心购买</div>
				</div>
            </div>
        </div>
		<div class="lianxi">
			<div class="left">
				<div style="font-size: 22px;color: #FF0036;">
					<img style="width: 22px;height: 22px;" src="../../assets/images/phone.png" alt="">
					售后电话
				</div>
				<div style="margin-left:25px">{{ dianhua[0] }}</div>
				<div style="margin-left:25px">{{ dianhua[1] }}</div>
				<div style="margin-top: 20px;margin-left:25px">联系地址：甘肃省兰州市城关区段家滩路108-1号2层</div>
			</div>
			<img style="width: 30%;" src="../../assets/images/index_luxian.png" alt="">
		</div>
        <div class="footer-bot">
			<copy-right />
		</div>
    </div>
</template>

<script>
	import {
		copyRight,
		shopServiceLists,
		friendlyLink,
        weQrcode
	} from "@/api/website"
	import CopyRight from "./CopyRight"
	import {
		mapGetters
	} from "vuex"
	import {
		helpList
	} from "@/api/cms/help"
	import { dianhua } from "@/api/pc"

	export default {
		props: {},
		data() {
			return {
				shopServiceList: [],
				linkList: [],
				helpList: [],
				ishide: false,
				activeName: "first",
                qrcode:"",
				dianhua:[]
			}
		},
		computed: {
			...mapGetters(["siteInfo"])
		},
		created() {
			// this.getShopServiceLists()
			// this.link()
			this.getHelpList()
            // this.getqrcodeimg()
			this.getdianhua()
		},
		mounted() {},
		watch: {},
		methods: {
			getdianhua(){
				dianhua({}).then(res => {
					this.dianhua=res.data[0].dianhua.split(',')
					console.log('dianhua',this.dianhua)
				})
				.catch(err => {
					this.$message.error(err.message)
				})
			},
		    getqrcodeimg(){
                weQrcode({}).then(res => {
                        if (res.code == 0 && res.data) {
                            this.qrcode = res.data
                        }
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                    })
            },
			getShopServiceLists() {
				shopServiceLists({}).then(res => {
						if (res.code == 0 && res.data) {
							this.shopServiceList = res.data
						}
					})
					.catch(err => {
						this.shopServiceList = []
					})
			},
			link() {
				friendlyLink({})
					.then(res => {
						if (res.code == 0 && res.data) {
							this.linkList = res.data
						}
					})
					.catch(err => {
						this.$message.error(err.message)
					})
			},
			linkUrl(url, target) {
				if (!url) return
				if (url.indexOf("http") == -1) {
					if (target) this.$router.pushToTab({
						path: url
					})
					else this.$router.push({
						path: url
					})
				} else {
					if (target) window.open(url)
					else window.location.href = url
				}
			},
			/**
			 * 获取帮助列表
			 */
			getHelpList() {
				helpList()
					.then(res => {
						if (res.code == 0 && res.data) {
							var arr = [];
							arr = res.data.slice(0, 4)
							console.log(arr)
							for (let i = 0; i < arr.length; i++) {
								arr[i].child_list = arr[i].child_list.slice(0, 4);
							}

							this.helpList = arr
						}
					})
					.catch(err => {})
			},
			/**
			 * 图片加载失败
			 */
			imageError() {
				this.siteInfo.web_qrcode = "../../assets/images/wxewm.png"
			},
			/**
			 * 跳转到帮助列表
			 */
			clickToHelp(id) {
				this.$router.push("/cms/help/listother-" + id)
			},
			/**
			 * 跳转到帮助详情
			 */
			clickToHelpDetail(id) {
				this.$router.push("/cms/help-" + id)
			}
		},
		components: {
			CopyRight
		}
	}
</script>

<style scoped lang="scss">
	.flex{
		text-align: center;
		img{
			width: 70px;
			height: 70px;
		}
		.text{
			font-size: 20px;
			font-weight: bold;
			margin-top: 10px;
		}
	}
    .lianxi{
		width: $width;
		margin: 20px auto;
		display: flex;
		justify-content: space-evenly;
		align-items: center;
		font-size:20px;
		font-weight: bold;
		.left{
			width: 42%;
		}
	}
	.footer {
        .footer-top {
            background-color: #fff;
            .service {
                margin: 0;
                padding: 0;
                width: $width;
                margin: 0 auto;
                padding-bottom: 35px;
                box-sizing: border-box;
                border-bottom: 1px solid #FF0036;
                display: flex;
                justify-content: space-around;
                align-items: center;
      //           li {
      //               list-style: none;
      //               line-height: 50px;
      //               text-align: center;
      //               flex: 1;

      //               .div {
      //                   width: 48px;
      //                   height: 48px;
      //                   line-height: 48px;
						// display: flex;
						// overflow: hidden;
						// align-items: center;
						// justify-content: center;
						// margin: 0 auto;
							
						// img {
						// 	height: 100%;
						// }
      //               }
      //               p {
      //                   font-size: 16px;
      //                   line-height: 20px;
      //                   margin-top: 10px;
						// display: block;
      //               }
      //           }
            
			}
        }

        .footer-center {
            width: $width;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            padding: 50px 0;
            .left {
                width: 300px;

                .left-phone {
                    font-size: 18px;
                    font-weight: 600;
                    padding-bottom: 10px;
					
					color: $base-color;
                    // border-bottom: 1px solid $base-color;
                    // margin-bottom: 12px;
                }
                .left-email {
					font-size: 18px;
					font-weight: 600;
                    // color: $base-color;
                }
            }
            .center {
                width: calc(100% - 550px);
                padding: 0 68px;
                display: flex;
                justify-content: space-between;

                p {
                    font-size: 18px;
                    font-weight: 600;
                    // color: #838383;
                    margin-bottom: 10px;
                    cursor: pointer;
                }

                .help-li {
					font-weight: 600;
                    font-size: 18px;
                    // color: #838383;
                    line-height: 30px;
                    cursor: pointer;
                }

                p:hover, .help-li:hover {
                    color: $base-color;
                }
            }
            .right {
                width: 250px;
                text-align: center;

                .el-image {
                    width: 120px;
                    height: 120px;
                    line-height: 120px;
                    text-align: center;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                p {
                    font-size: 18px;
                    color: #838383;
                }
            }

            .qrcode-hide {
                display: none;
            }
        }

        .footer-bot {
            background: #242424;
            color: #9D9D9D;
        }

        .friendly-link {
            width: $width;
            margin: 0 auto;
            border: 1px solid #e9e9e9;

            .link-title {
                line-height: 30px;
                padding: 10px 0 5px;
                margin: 0px 0 15px;
                border-bottom: 1px solid #e8e8e8;
            }
            .link-item {
                width: 10%;
                height: 50px;
                line-height: 47px;
                float: left;
                text-align: center;
                overflow: hidden;
                margin: 0 6px 10px 6px;
                -webkit-transition: opacity 0.3s, box-shadow 0.3s;
                transition: opacity 0.3s, box-shadow 0.3s;
                border: 1px solid #fff;
                border-left: none;
                cursor: pointer;
            }
            .link-item:hover {
                width: -webkit-calc(10% + 1px);
                width: calc(10% + 1px);
                margin-left: 4px;
                position: relative;
                opacity: 1;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                border: 1px solid #dddddd;
            }
            .clear {
                clear: both;
            }
        }
    }
</style>
<style lang="scss">
    .friendly-link {
        .el-tabs__nav-scroll {
            padding-left: 30px;
            height: 50px;
            line-height: 50px;
        }
        .el-tabs__content {
            padding: 0 20px;
        }
        .el-tabs__nav-wrap::after {
            height: 1px;
        }
        .el-tabs__nav {
            padding: 0 20px;
        }
        .el-tabs__active-bar {
            padding: 0 20px;
        }
    }
</style>

<template>
    <div class="footer-bottom">
        <p>
            {{ copyRight.copyright_desc }}
            <a v-if="copyRight.icp" class="footer-link" href="https://beian.miit.gov.cn" target="_blank">备案号：{{ copyRight.icp }}</a>
			<!-- <span style="margin-left:2vw;"><i class="iconfont icondianhua"></i>联系电话：{{ dianhua[0] }} {{ dianhua[1] }}</span> -->
		</p>
        <p>
            <a v-if="copyRight.gov_record" class="footer-link" :href="copyRight.gov_url" target="_blank">
                <img src="@/assets/images/gov_record.png" alt="公安备案" />
                <span>{{ copyRight.gov_record }}</span>
            </a>
        </p>
		<!-- <div class="site-info">
		    <p><i class="iconfont icondianhua"></i>联系电话：{{ dianhua[0] }} {{ dianhua[1] }}</p>
		</div> -->
    </div>
</template>

<script>
    import { copyRight } from "@/api/website"
    import { mapGetters } from "vuex"
	import { dianhua } from "@/api/pc"
    export default {
        props: {},
        data() {
            return {
				dianhua:[]
			}
        },
        computed: {},
        created() {
            this.$store.dispatch("site/copyRight")
			this.getdianhua()
        },
        mounted() {},
        watch: {},
        methods: {
			getdianhua(){
				dianhua({}).then(res => {
					this.dianhua=res.data[0].dianhua.split(',')
					console.log('dianhua',this.dianhua)
				})
				.catch(err => {
					this.$message.error(err.message)
				})
			},
		},
        computed: {
            ...mapGetters(["copyRight", "siteInfo"])
        }
    }
</script>

<style scoped lang="scss">
    .footer-bottom {
        width: 100%;
        // height: 85px;
        margin: 0 auto;
        padding: 20px 0;
        p {
            margin: 0;
            width: 100%;
            box-sizing: border-box;
            text-align: center;
            img {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
            .footer-link, .el-link.el-link--default {
                color: #9d9d9d;
            }
			.footer-link:hover, .el-link.el-link--default:hover {
				color: $base-color;
			}
        }
        .site-info {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                width: auto;
                margin: 0 10px;

                i {
                    vertical-align: bottom;
                    margin-right: 5px;
                }
            }
        }
    }
</style>

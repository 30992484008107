<template>
	<div class="header">
		<ns-header-top />
		<ns-header-mid />

		<div class="nav">
			<div class="shop-sort" @mouseover="shopHover" @mouseleave="shopLeave">
				<router-link to="/category">所有商品分类</router-link>
				<i class="iconfont iconweibiaoti35"></i>
			</div>
			<div class="shop-list" :class="forceExpand || isShopHover || isIndex ? 'shop-list-active' : 'shadow'"
				@mouseover="shopHover" @mouseleave="shopLeave">
				<div class="list-item" v-for="(item, index) in goodsCategoryTree" :key="index"
					@mouseover="getSelectedCategory(item)" @mouseleave="selectedCategory = -1 , isShopHover = false">
					<router-link :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }"
						target="_blank">{{ item.category_name }}
						<i class="el-icon-arrow-right" aria-hidden="true" v-if="item.child_list"></i>
						<i v-else style="display: inline-block;width: 14px;height: 14px;"></i>
					</router-link>
					<div class="cate-part" v-if="item.child_list"
						:class="{ show: selectedCategory == item.category_id }">
						<div class="cate-part-flex">
							<div class="cate-part-col1">
								<!-- <div class="cate-channel">
															<router-link class="cate-channel-lk" to="/brand" target="_blank">
																手机城
																<i class="el-icon-arrow-right" aria-hidden="true"></i>
															</router-link>
							</div>-->
								<div class="cate-detail" v-for="(item, index) in selectedCategoryArray">
									<dl class="cate-detail-item" v-for="(second_item, second_index) in item"
										:key="second_index+'^v^'">
										<dt class="cate-detail-tit">
											<router-link target="_blank"
												:to="{ path: '/list', query: { category_id: second_item.category_id, level: second_item.level } }">
												<div class="img-box"><img :src="$img(second_item.image)"
														@error="imageError(index, second_index )"></img></div>
												<span>{{ second_item.category_name }}</span>
												<!-- 							<i class="el-icon-arrow-right" aria-hidden="true" v-if="second_item.child_list"></i> -->
												<!-- 			<i v-else style="display: inline-block;width: 14px;height: 14px;"></i> -->
											</router-link>
										</dt>
										<!-- <dd class="cate-detail-con" v-if="second_item.child_list">
																	<router-link v-for="(third_item, third_index) in second_item.child_list" :key="third_index" target="_blank"
																	 :to="{ path: '/list', query: { category_id: third_item.category_id, level: third_item.level } }">
																		{{ third_item.category_name }}
																	</router-link>
																</dd> -->
									</dl>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<nav>
				<ul>
					<li v-for="(nav_item, nav_index) in navList" :key="nav_index"
						:class="nav_item.url == navSelect ? 'router-link-active' : ''"
						@click="navUrl(nav_item.child_list[0].id)">
						<span>{{ nav_item.class_name }}</span>
					</li>
					<li>
						<router-link to="/cms/notice">公告</router-link>
					</li>
					<li>
						<router-link to="/cms/zhengfu">政府文件</router-link>
					</li>
					<li>
						<a href="https://zgxffpgsg.com/mobile" target="_blank">						
							<span>手机端平台</span>
						</a>
					</li>
				</ul>
			</nav>
		</div>
	</div>
</template>

<script>
	import NsHeaderTop from './NsHeaderTop';
	import NsHeaderMid from './NsHeaderMid';
	import {
		tree
	} from '@/api/goods/goodscategory';
	import {
		navList
	} from '@/api/website';
	import {
		mapGetters
	} from 'vuex';
	import {
		helpList
	} from "@/api/cms/help"
	export default {
		props: {
			forceExpand: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				isShopHover: false,
				isIndex: false,
				thisRoute: '',
				goodsCategoryTree: [],
				selectedCategory: -1,
				navList: [],
				navSelect: '',
				selectedCategoryArray: [],
			};
		},
		components: {
			NsHeaderTop,
			NsHeaderMid
		},
		computed: {
			...mapGetters(['defaultGoodsImage'])
		},
		beforeCreate() {},
		created() {
			this.$store.dispatch('cart/cart_count');
			this.getTree();
			this.nav();
			if (this.$route.path == '/' || this.$route.path == '/index') this.isIndex = true;
 
		},
		mounted() {},
		watch: {
			$route: function(curr) {
				this.initNav(curr.path);
				if (curr.path == '/' || curr.path == '/index') this.isIndex = true;
				else this.isIndex = false;

				if (curr.path == '/list') {
					this.navSelect = '';
				}
			}
		},
		methods: {
			getSelectedCategory(item) {
				this.selectedCategoryArray = []
				this.selectedCategory = item.category_id
				if (item.child_list) {
					if (item.child_list.length > 0 && item.child_list.length < 6) {
						this.selectedCategoryArray[0] = item.child_list
					} else if (item.child_list.length >= 6 && item.child_list.length < 12) {
						this.selectedCategoryArray[0] = item.child_list.slice(0, 6)
						this.selectedCategoryArray[1] = item.child_list.slice(6, 12)
					} else if (item.child_list.length >= 12 && item.child_list.length < 18) {
						this.selectedCategoryArray[0] = item.child_list.slice(0, 6)
						this.selectedCategoryArray[1] = item.child_list.slice(6, 12)
						this.selectedCategoryArray[2] = item.child_list.slice(12, 18)
					} else if (item.child_list.length >= 18 && item.child_list.length < 23) {
						this.selectedCategoryArray[0] = item.child_list.slice(0, 6)
						this.selectedCategoryArray[1] = item.child_list.slice(6, 12)
						this.selectedCategoryArray[2] = item.child_list.slice(12, 18)
						this.selectedCategoryArray[3] = item.child_list.slice(18, 23)
					} else {
						this.selectedCategoryArray[0] = item.child_list.slice(0, 6)
						this.selectedCategoryArray[1] = item.child_list.slice(6, 12)
						this.selectedCategoryArray[2] = item.child_list.slice(12, 18)
						this.selectedCategoryArray[3] = item.child_list.slice(18, 23)
						this.selectedCategoryArray[3].length.category_id = item.category_id
						this.selectedCategoryArray[3].length.level = 1
						this.selectedCategoryArray[3].length.category_name = '更多'
					}

				}
			},
			// 默认图片
			imageError(e, v) {
				this.selectedCategoryArray[e][v].image = this.defaultGoodsImage;
			},
			getTree() {
				tree({
						level: 3,
						template: 2
					})
					.then(res => {
						if (res.code == 0 && res.data) {
							if (res.data.length >= 11) this.goodsCategoryTree = res.data
							this.goodsCategoryTree = res.data.slice(0, 12)
						}
					})
					.catch(err => {
						this.$message.error(err.message);
					});
			},
			nav() {
				helpList({})
					.then(res => {
						if (res.code == 0 && res.data) {
							this.navList = res.data;
							for (let i in this.navList) {
								this.navList[i]['url'] = JSON.parse(this.navList[i]['nav_url']).url;
							}
							this.initNav(this.$route.path);
						}
					})
					.catch(err => {
						// this.$message.error(err.message);
					});
			},
			navUrl(id) {
				// this.$router.push("/cms/help/listother-" + id)
				this.$router.push({ path: "/cms/help-" + id });
			},
			initNav(path) {
				for (let i in this.navList) {
					if (this.navList[i]['url'] == path) {
						this.navSelect = path;
						continue;
					}
				}
			},
			//鼠标移入显示商品分类
			shopHover() {
				this.isShopHover = true;
			},
			//鼠标移出商品分类隐藏
			shopLeave() {
				this.isShopHover = false;
			}
		}
	};
</script>

<style scoped lang="scss">
	.header {
		width: 100%;
		height: 180px;
		background-color: #fff;

		.shadow {
			box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
		}

		.border {
			border: 1px solid #f5f5f5;
		}

		.nav {
			width: 1210px;
			height: 41px;
			margin: 0 auto;
			position: relative;

			.shop-sort {
				width: 210px;
				height: 41px;
				color: #fff;
				background-color: $base-color;
				float: left;
				padding: 10px 10px 11px 10px;
				box-sizing: border-box;

				a {
					font-size: 14px;
					line-height: 20px;
					float: left;
					color: #fff;
				}

				i {
					font-size: 14px;
					float: right;
				}
			}

			.shop-list {
				width: 210px;
				height: 430px;
				position: absolute;
				left: 0;
				top: 41px;
				background-color: #4B484D;
				display: none;
				padding: 12px 0;
				box-sizing: border-box;
				font-size: $ns-font-size-base;
				z-index: 10;
				// box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);

				a:hover {
					color: $base-color;
				}

				.list-item {
					padding-left: 25px;
					height: 35px;
					line-height: 35px;

					a {
						// white-space: nowrap;
						// overflow: hidden;
						// text-overflow: ellipsis;
						width: 100%;
						display: block;
						font-size: 14px;
						color: #FFFFFF;
						font-weight: 400;

						&:hover {
							color: $base-color !important;
						}
					}

					i {
						padding-left: 80px;
						font-weight: 800;
					}

					&:hover {
						a {
							color: $base-color !important;
						}

						background-color: #fff;
						-webkit-transition: 0.2s ease-in-out;
						-moz-transition: -webkit-transform 0.2s ease-in-out;
						-moz-transition: 0.2s ease-in-out;
						transition: 0.2s ease-in-out;
					}

					.cate-part {
						display: none;
						position: absolute;
						left: 209px;
						top: 0;
						z-index: auto;
						// width: 998px;
						// width: 758px;
						height: 426px;
						border: 1px solid #f7f7f7;
						background-color: #fff;
						-webkit-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
						-moz-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
						box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
						-webkit-transition: top 0.25s ease;
						-o-transition: top 0.25s ease;
						-moz-transition: top 0.25s ease;
						transition: top 0.25s ease;

						.cate-part-flex {}

						a {
							color: #000 !important;

							&:hover {
								color: $base-color !important;
							}
						}

						&.show {
							display: block;
						}
					}

					.cate-part-col1 {
						// float: left;
						// width: 740px;
						padding: 5px 0;
						display: flex;
						justify-content: center;

						// .cate-channel {
						// 	display: block;
						// 	overflow: hidden;
						// 	padding-left: 20px;
						// 	height: 24px;
						// 	.cate-channel-lk {
						// 		height: 24px;
						// 		float: left;
						// 		padding: 0 10px;
						// 		margin-right: 10px;
						// 		background-color: #7c7171;
						// 		line-height: 24px;
						// 		color: #fff;
						// 	}
						// }
						.cate-detail {
							height: 426px;
							display: flex;
							align-items: flex-start;
							flex-direction: column;
							flex-wrap: wrap;
							justify-content: flex-start;
						}

						.cate-detail-item {
							position: relative;
							height: 60px;
							line-height: 60px;
							padding-left: 20px;
							padding: 4px 0;

							.cate-detail-tit {
								// position: absolute;
								// top: 4px;
								// left: 0;
								// overflow: hidden;
								width: 210px;
								text-align: left;
								// white-space: nowrap;
								// text-overflow: ellipsis;
								font-weight: 700;
								padding-left: 20px;

								span {
									display: inline-block;
									width: 120px;
									overflow: hidden;
									white-space: nowrap;
									text-overflow: ellipsis;
									padding-left: 10px;
								}

								.img-box {
									float: left;
									width: 40px;
									height: 40px;
									padding-right: 20px;

									img {
										width: 100%;
										height: 100%;
										border-radius: 50%;
									}
								}
							}

							.cate-detail-con {
								// overflow: hidden;
								padding: 6px 0;
								border-top: 1px solid #eee;

								a {
									height: 16px;
									float: left;
									margin: 4px 0;
									padding: 0 10px;
									white-space: nowrap;
									border-left: 1px solid #e0e0e0;
									line-height: 16px;
								}
							}

							&:first-child .cate-detail-con {
								border-top: none;
							}
						}
					}

					// .sub-class-right {
					// 	display: block;
					// 	width: 240px;
					// 	height: 439px;
					// 	float: right;
					// 	border-left: solid 1px #e6e6e6;
					// 	overflow: hidden;
					// 	.adv-promotions {
					// 		display: block;
					// 		height: 441px;
					// 		margin: -1px 0;
					// 		a {
					// 			background: #fff;
					// 			display: block;
					// 			width: 240px;
					// 			height: 146px;
					// 			border-top: solid 1px #e6e6e6;
					// 			img {
					// 				background: #d3d3d3;
					// 				width: 240px;
					// 				height: 146px;
					// 			}
					// 		}
					// 	}
					// }
				}
			}

			.shop-list-active {
				display: block;
			}

			nav {
				width: 934px;
				height: 36px;
				float: left;
				font-size: 14px;

				ul {
					margin: 0;
					padding: 0;
					width: 100%;
					height: 100%;

					li {
						cursor: pointer;
						list-style: none;
						float: left;
						padding: 8px 24px;

						a {
							&:hover {
								color: $base-color;
							}
						}
					}

					li:hover {
						color: $base-color;
						font-weight: bold;
					}

					.router-link-active {
						color: $base-color;
						font-weight: bold;
					}
				}
			}
		}
	}
</style>
import http from "../utils/http"

/**
 * 楼层
 * @param {object} params
 */
export function floors(params) {
    return http({
        url: "/api/pc/floors",
        data: params
    })
}

/**
 * 获取热门搜索关键词
 */
export function apiHotSearchWords(params) {
    return http({
        url: "/api/goods/hotSearchWords",
        data: params
    })
}

/**
 * 获取默认搜索关键词
 */
export function apiDefaultSearchWords(params) {
    return http({
        url: "/api/goods/defaultSearchWords",
        data: params
    })
}
/**
 * 获取首页浮层
 */
export function floatLayer(params) {
    return http({
        url: "/api/pc/floatLayer",
        data: params
    })
}

/**
 * 获取首页banner
 */
export function banner(params) {
    return http({
        url: "/api/banner/lists",
        data: params
    })
}
/**
 * 获取首页电话
 */
export function dianhua(params) {
    return http({
        url: "/api/shou/dianhua",
        data: params
    })
}

/**
 * 卡券兑换 绑卡
 */
export function bangka(params) {
    return http({
        url: "/supermember/api/membercard/bangka",
        data: params
    })
}
/**
 * 卡券兑换 充钱
 */
export function chongqian(params) {
    return http({
        url: "/supermember/api/membercard/chongqian",
        data: params
    })
}
/**
 * 我的卡券
 */
export function kaquan(params) {
    return http({
        url: "/supermember/api/membercard/pc_lingkalist",
        data: params
    })
}
